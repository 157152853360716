.main {
    @extend .container;
    position: relative;
}

.loop {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
    margin: 0 -9px 60px;
    color: #75818D;
}

.article {
    padding: 0 9px;
    margin-bottom: 36px;
    width: 100%;

    @media (min-width: $screen-sm) {
        width: 50%;
    }
    @media (min-width: $screen-md) {
        width: 33.3333%;
    }

    @media (max-width: $screen-xs-max) {
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__image img {
        display: block;
        box-shadow: 0 0 1px 1px rgba(0,0,0,.04), 0 5px 8px rgba(0,0,0,.05);
        border-radius: 2px;
    }
}

.page-title {
    font-size: 14px;
    font-weight: 500;
    color: #54575A;
    margin-bottom: 24px;
}

.article {
    
    &__content {
        margin: 18px 0 0;
    }

    &__title {
        font-size: 21px;
        margin: 0;

        a {
            color: #54575A;

            &:hover {
                color: #ED6C6C;
            }
        }
    }

    &__excerpt {
        p {
            margin: 0;
        }
    }

    &__cat {
        font-size: 12px;
        margin-top: 12px;

        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
        }

        li {
            display: inline-block;
        }

        a {
            display: inline-block;
            padding: 3px 12px;
            border: 1px solid rgba(#A1AAB4,.5);
            border-radius: 24px;
            color: #8D959E;

            &:hover {
                border: 1px solid rgba(#A1AAB4,1);
            }
        }
    }
}

.wp-pagenavi {
    margin-bottom: 24px;
    text-align: center;
    position: relative;

    span {
        display: inline-block;
    }

    .current {
        font-weight: 700;
        color: #54575A;
        margin: 0 1px;
        padding: 6px 12px;
    }

    a {
        padding: 6px 12px;
        border-radius: 2px;
        display: inline-block;

        &:hover {
            background: #ED6C6C;
            color: #fff;
        }
    }

    .extend {
        padding: 6px;
    }

    .pages {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .nextpostslink {
        border: 1px solid rgba(#A1AAB4,.5);
        padding-left: 18px;
        padding-right: 18px;
        position: absolute;
        right: 0;
        transform: translateY(-1px);

        &:hover {
            border-color: #ED6C6C;
        }
    }
}