.footer {
    @extend .container;
    color: #75818D;

    &__content {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 48px;
        padding-top: 36px;
        border-top: 1px solid rgba(#CED3D8,.5);
    }

    a {
        font-weight: 500;
    }

    &__logo {
        width: percentage(2/12);
        padding-right: 18px;
        transform: translateY(5px);
    }

    &__desc {
        width: percentage(6/12);

        @media (max-width: $screen-xs-max) {
            width: percentage(10/12)
        }
    }

    &__menu {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
            display: inline-block;

            &:not(:last-child){
                margin-right: 12px;
            }
        }
    }

    &__copyright {
        width: percentage(4/12);
        padding-left: 18px;
        text-align: right;
        font-size: 12px;
        color: #97A4B2;
        
        @media (min-width: $screen-sm) {
            transform: translateY(3px);
        }

        @media (max-width: $screen-xs-max) {
            width: auto;
            text-align: left;
            padding-left: percentage(2/12);
            margin-top: 36px;
        }
    }    
}

::-moz-selection {
    color: #fff;
    background-color: #ED6C6C;
}

::selection {
    color: #fff;
    background-color: #ED6C6C;
}

