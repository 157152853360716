.header, .navigation, .categories {
    @extend .container;
}

.logo {
    margin: 72px 0;
    display: flex;

    @media (max-width: $screen-xs-max) {
        margin: 36px 0;
    }

    &__icon {
        width: 30px;
        height: 48px;
        background: url(../img/proddduct-logo.png) no-repeat;
        background-size: 30px 48px;
        display: inline-block;
        margin-right: 16px;
    }

    &__wordmark {
        transform: translateY(2px);

        h2 {
            width: 134px;
            height: 28px;
            margin: 0 0 4px;
            background: url(../img/proddduct.png) no-repeat;
            background-size: 134px 28px;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
        }
        
        p {
            margin: 0 0 0 -1px;
            font-size: 13px;
            color: #97A4B2;
        }
    }
}

nav[role="navigation"] {
    margin-bottom: 60px;
    position: relative;

    &:after {
        content:'';
        position: absolute;
        left: 0;
        right: 0;
        height: 1px;
        background-color: rgba(#CED3D8,.5);
        display: block;

        margin: 0 -10px;
    }
}

.navigation {
    list-style: none;
    font-weight: 500;
    display: flex;
    padding-bottom: 24px; 

    a {
        padding: 6px 0;
        display: block;
    }

    li:not(:last-child) {
        margin-right: 24px;

        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }

    .current-menu-item a {
        color: #54575A;
        position: relative;

        &:after {
            position: absolute;
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            bottom: 0;
            background-image: linear-gradient(90deg, #ED886C 0%, #ED6C6C 100%);
        }
    }
}

.nav--all {

    span {
        width: 10px;
        height: 5px;
        display: inline-block;
        background: url(../img/nav-all.svg) no-repeat;
        margin-left: 6px;
        vertical-align: 1px;
        transition: transform .3s ease-in-out;
    }

    &.is-toggled {
        span {
            transform: translateY(-1px) rotate(-180deg);

        }
    }
}

.all-categories {
    background: rgba(206,211,216,.5);
    margin: 0 -10px;
    padding: 0 10px;
}

.categories {
    list-style: none;
    padding-top: 18px;
    padding-bottom: 18px;
    display: flex;
    flex-wrap: wrap;
    font-weight: 500;

    li {
        width: 100%;

        span {
            display: none;
        }

        @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
            width: 50%;

            &:nth-child(even) {
                padding-left: 10px;
            }
        }

        @media (min-width: $screen-md) {
            width: 33.3333%;
            padding-left: 10px;

            &:nth-child(3n+1) {
                padding-left: 0;
            }
        }
    }

    a {
        display: block;
        padding: 6px 0;
    }
}