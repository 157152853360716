.content {
    margin-bottom: 60px;

    &--page {

        @media (min-width: $screen-sm) {
            width: percentage(8/12);
            margin-left: auto;
            margin-right: auto;
        }
        
    }
}

.article--single {
    font-size: 15px;

    h1 {
        color: #33383C;
    }
}


/* ----- 
product 
----- */

.content {
    &--single {
        @media (min-width: $screen-sm) {
            width: percentage(8/12);
            margin-left: auto;
            margin-right: auto;
        }
    }

    a {
        border-bottom: 1px solid rgba(#75818D,.3);
    }
}

.product {
    .article__cat {
        margin-bottom: 18px;

        @media (min-width: $screen-sm) {
            position: absolute;
            left: 0;
            padding: 0 18px;
            top: -2px;
        }

        @media (orientation: landscape) {
            padding-left: constant(safe-area-inset-left);
            padding-left: env(safe-area-inset-left);
        }
    }

    .article__header {
        font-size: 16px;
        margin-bottom: 60px;

        h1 {
            font-size: 30px;
            margin-bottom: 0;
        }
    }

    &__url {
        margin-top: 24px;
        font-size: 14px;
        color: #75818D;
        border-bottom: 1px solid rgba(#75818D,.2);
    }

    &__header {
        font-weight: 500;
        font-size: inherit;

        @media (min-width: $screen-sm) {
            position: absolute;
            max-width: percentage(2/12);
            padding: 0 18px;
            left: 0;

            @media (orientation: landscape) {
                /* iOS 11 */
                padding-left: constant(safe-area-inset-left);
                
                /* iOS 11.2+ */
                padding-left: env(safe-area-inset-left);
            }
        }
        // transform: translateX(calc(-100% - 18px));
        
    }

    &__image, &__font, &__color, &__screenshot, &__link {
        margin-bottom: 60px;
    }

    &__list {
        list-style: none;
        padding-left: 0;

        li:not(:last-child) {
            margin-bottom: 6px;
        }
    }
    
}

.product__image {
    .device {
        list-style: none;
        padding-left: 0;
        display: flex;
        font-size: 14px;
        margin-bottom: 24px;

        li {
            &:not(:last-child){
                margin-right: 12px;
            }
        }

        a {
            display: block;
            padding: 0 12px 2px;
            font-weight: 500;
            border-radius: 30px;
            box-shadow: 0 0 0 transparent;
            transition: all .1s;
            border-bottom: none;

            &.current {
                background-image: linear-gradient(90deg, #ED886C 0%, #ED6C6C 100%);
                box-shadow: 0 1px 3px 0 rgba(0,0,0,.1);
                color: #fff;
            }
        }
    }


    img {
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.08);
        border-radius: 2px;
    }
    .screen {
        text-align: center;
    }
    .tablet {
        width: 600px;
    }
    .mobile {
        width: 375px;
    }
}

.product__color {

    .product__list {
        display: flex;
        flex-wrap: wrap;

        li {
            margin-bottom: 12px;
        }
    }
    li {
        width: 60px;
        height: 60px;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.3);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: rgba(#fff,.8);

        &:not(:last-child) {
            margin-right: 12px;
        }

        span {
            opacity: 0;
            transition: opacity .2s linear;
        }

        &:hover {
            span {
                opacity: 1;
            }
        }
    }
}

.product__screenshot {
    .product__list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -9px;
        margin-right: -9px;
        font-size: 14px;
        color: #75818D;

        a {
            border-bottom: none;
        }

        li {
            padding: 0 9px;
            
            @media (min-width: $screen-sm) {
                width: 50%;
            }
        }

        img {
            box-shadow: 0 1px 3px 0 rgba(0,0,0,.1);
            display: block;
        }

        p {
            &:first-of-type {
                margin-top: 6px;
            }
        }
    }
}


/* attachment */
.content--attachment {
    img {
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.1);
    }
}