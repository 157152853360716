*, *:before, *:after {
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    border: 10px solid #fff;
    color: #54575A;
    background: #f6f6f6;
    font-size: 14px;
    font-family: 'Barlow', 'Roboto', sans-serif;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}

.container {
    max-width: 1200px;
    padding: 0 18px;
    margin: 0 auto;

    @media (orientation: landscape) {
        /* iOS 11 */
        padding-left: constant(safe-area-inset-left);
        padding-right: constant(safe-area-inset-right);
        
        /* iOS 11.2+ */
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
    }
    
}

a {
    transition: all .1s;
    color: #75818D;
    text-decoration: none;

    &:hover {
        color: #54575A;
    }
}

h1, h2, h3, h4, h5, h6, ul, p {
    margin: 0 0 18px;
}

img {
    max-width: 100%;
    height: auto;
}